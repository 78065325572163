import merge from '~lodash.mergewith'
import { init, Replay, vueRouterInstrumentation, BrowserTracing } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { CaptureConsole, ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F3b922d410ae54362a643cf2414c523de@o272456.ingest.sentry.io\u002F4504911200911360",
    environment:"production",
    allowUrls:[new RegExp("https:\\\u002F\\\u002Fwww\\.missmary", ""),new RegExp("https:\\\u002F\\\u002Fstaging-", ""),new RegExp("https:\\\u002F\\\u002Fmm-frontend", ""),new RegExp("https:\\\u002F\\\u002Flocalhost", ""),new RegExp("http:\\\u002F\\\u002Flocalhost", ""),new RegExp("http:\\\u002F\\\u002F127.0.0.1", "")],
    trackComponents:true,
    hooks:["activate","create","destroy","mount","update"],
    tracesSampleRate:0.01,
    release:"v4.3.34",
    replaysOnErrorSampleRate:0.001,
    replaysSessionSampleRate:0,
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
    new CaptureConsole({ levels:["error"] }),
    new Replay(),
  ]

  const { browserTracing, vueOptions, ...tracingOptions } = {"tracesSampleRate":0.01,"browserTracing":{"tracePropagationTargets":[new RegExp("https:\\\u002F\\\u002Fwww\\.missmary", ""),new RegExp("https:\\\u002F\\\u002Fstaging-", ""),new RegExp("https:\\\u002F\\\u002Fmm-frontend", ""),new RegExp("https:\\\u002F\\\u002Flocalhost", ""),new RegExp("http:\\\u002F\\\u002Flocalhost", ""),new RegExp("http:\\\u002F\\\u002F127.0.0.1", "")]},"vueOptions":{"trackComponents":true,"tracing":true}}
  config.integrations.push(new BrowserTracing({
    ...(ctx.app.router ? { routingInstrumentation: vueRouterInstrumentation(ctx.app.router) } : {}),
    ...browserTracing,
  }))
  merge(config, vueOptions, tracingOptions)

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
